import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import { Newsletter as NewsletterPage } from '@common/pages'

const Newsletter = () => {
  const { desktop, mobile } = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "newsletter/bg-desktop.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        mobile: file(relativePath: { eq: "newsletter/bg-mobile.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  return <NewsletterPage desktop={desktop} mobile={mobile} />
}

export default Newsletter
